import { useState } from "react";
import "./Dots.scss";

const Dots = (props) => {
  const { collectables, skyState } = props;
  const [textState, setTextState] = useState(null);

  const handlePointerLeave = () => {
    setTextState(null);
  };
  return (
    <div className="dots__wrapper">
      <div className={`dots__pentagon ${skyState === 5?'active':''}`}></div>
      <a 
      className={`dots__completed-wrapper ${skyState === 5?'active':''}`}
      href="https://mailchi.mp/b480b6d4e5f9/5blndq93gm"
      target="_blank"
      rel="noreferrer"
      >
        <img
          src="./assets/icons/object.svg"
          alt="UNLOCK"
          className="dots__completed-icon"
        />
        <div className="dots__unlock-button">
          UNLOCK
          <br />
          STEMS
        </div>
      </a>

      {collectables &&
        collectables.map((collectable, index) => {
          return (
            <div
              className={`dots__dot ${collectable.name}`}
              style={{
                pointerEvents: collectable.isComplete ? "all" : "none",
              }}
              key={index}
              onPointerEnter={() => {
                setTextState(collectable.name);
              }}
              onPointerLeave={handlePointerLeave}
            >
              <div
                className="dots__inner-dot"
                style={{
                  opacity: collectable.isComplete
                    ? 1
                    : collectable.percentageHovered / 100,
                }}
              ></div>
            </div>
          );
        })}
      <div
        className="dots__text-wrapper"
        style={{ opacity: textState !== null ? 1 : 0 }}
      >
        {textState}
      </div>
    </div>
  );
};

export default Dots;
