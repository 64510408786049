import "./BuyStream.scss";

const BuyStream = () => {
  return (
    <a
      href="https://orcd.co/kiimi_sgd"
      target="_blank"
      rel="noreferrer"
      className="buy-stream__wrapper"
    >
      STREAM & BUY
    </a>
  );
};

export default BuyStream;