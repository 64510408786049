import Altar from "./Altar";
import Floor from "./Floor";
import Fountain from "./Fountain";

const Scenery = () => {
  return (
    <>
      <Floor />
      <Fountain />
      <Altar />
    </>
  );
};

export default Scenery;
