import { useLoader } from "@react-three/fiber";
import {
  TextureLoader,
  EquirectangularReflectionMapping,
  DoubleSide,
} from "three";

import Ocean from "./Ocean";

const Fountain = () => {
  const envMap = useLoader(TextureLoader, "./assets/textures/hdricopy.png");
  envMap.mapping = EquirectangularReflectionMapping;

  return (
    <>
      <mesh
        position={[0, 0, 0]}
        rotation-y={Math.PI / 2}
        castShadow
        receiveShadow
        side={DoubleSide}
      >
        <cylinderGeometry args={[20, 18, 3, 5, 15, true, 0.3]} />
        <meshPhongMaterial
          color={0xeeeeee}
          envMap={envMap}
          reflectivity={1}
          shininess={100}
        />
      </mesh>
      <Ocean />
    </>
  );
};

export default Fountain;
