import { useFrame } from "@react-three/fiber";
import { Sky } from "@react-three/drei";
import { lerp } from "three/src/math/MathUtils";
import { useRef, useState } from "react";
import { Color } from "three";

const SkyComponent = (props) => {
  const { skyState } = props;

  const sky = useRef();
  const pointLight = useRef();
  const ambLight = useRef();

  
  const colors = [
      new Color('rgb(255, 234, 217)'),
      new Color('rgb(255, 218, 186)'),
      new Color('rgb(255, 185, 125)'),
      new Color('rgb(255, 158, 74)'),
      new Color('rgb(255, 119, 0)'),
  ]
  
  const [ skyArgs, setSkyArgs] = useState({
      inclination : 0.5025,
      exposure: 1,
      mieDirectionalG: 0.6,
      rayleigh: 4,
      turbidity: 20,
      mieCoefficient: 0.1,
      azimuth : 0.25,
  })

  const handleChangeSky = (inclinationToValue, lgToValue, exposureToValue) => {
    const newSkyArgs = {...skyArgs};
    if(skyArgs.inclination > inclinationToValue){
      newSkyArgs.inclination -= 0.0001;
    }
    if(skyArgs.mieDirectionalG < lgToValue){
      newSkyArgs.mieDirectionalG += 0.001;
    }
    if(skyArgs.exposure < exposureToValue){
      newSkyArgs.exposure += 0.001;
    }
    setSkyArgs(newSkyArgs);
  }
  useFrame(()=>{
    switch (skyState) {
      case 1:
        pointLight.current.position.y = lerp(pointLight.current.position.y, 50, 0.05);
        ambLight.current.color.lerp(colors[0], 0.025);
        handleChangeSky(0.5025, 0.7, 0.9)
        break;
      case 2:
        pointLight.current.position.y = lerp(pointLight.current.position.y, 40, 0.05);
        ambLight.current.color.lerp(colors[1], 0.025);
        handleChangeSky(0.502, 0.8, 0.8)

        break;
      case 3:
        pointLight.current.position.y = lerp(pointLight.current.position.y, 30, 0.05);
        ambLight.current.color.lerp(colors[2], 0.025);
        handleChangeSky(0.5015, 0.9, 0.7)

        break;
      case 4:
        pointLight.current.position.y = lerp(pointLight.current.position.y, 10, 0.05);
        ambLight.current.color.lerp(colors[3], 0.025);
        handleChangeSky(0.501, 0.95, 0.6)

        break;
      case 5:
        pointLight.current.position.y = lerp(pointLight.current.position.y, 5, 0.05);
        ambLight.current.color.lerp(colors[4], 0.025);
        handleChangeSky(0.5005, 0.98, 0.5)

        break;
      default:
        break;
    }
  })

  return (
    <>
      <Sky 
      {...skyArgs} 
      distance={450000}
      ref={sky}/>

      <ambientLight 
      ref={ambLight}
      intensity={0.3}
      />
      <pointLight
        ref={pointLight}
        position={[0, 60, -200]}
        intensity={0.3}
        castShadow
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
      />
    </>
  );
};

export default SkyComponent;
