import { useEffect, useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useLoader, useFrame } from "@react-three/fiber";
import {
  AnimationMixer,
  DoubleSide,
  MeshPhongMaterial,
  TextureLoader,
  EquirectangularReflectionMapping,
} from "three";
import { lerp } from "three/src/math/MathUtils";

const Kim = (props) => {
  const { skyState } = props;
  const group = useRef();
  const { nodes, animations } = useGLTF("./assets/models/Kim.glb");
  const envMap = useLoader(TextureLoader, "./assets/textures/hdricopy.png");
  envMap.mapping = EquirectangularReflectionMapping;

  const actions = useRef();
  const [mixer] = useState(() => new AnimationMixer());

  useEffect(() => {
    actions.current = { idle: mixer.clipAction(animations[0], group.current) };
    actions.current.idle.play();
    return () => animations.forEach((clip) => mixer.uncacheClip(clip));
  }, [animations, mixer]);

  useFrame((state, delta) => {
    mixer.update(delta);

    switch (skyState) {
      case 1:
        group.current.position.y = lerp(group.current.position.y, -20, 0.05);
        break;
      case 2:
        group.current.position.y = lerp(group.current.position.y, -30, 0.05);
        break;
      case 3:
        group.current.position.y = lerp(group.current.position.y, -40, 0.05);
        break;
      case 4:
        group.current.position.y = lerp(group.current.position.y, -50, 0.05);
        break;
      case 5:
        group.current.position.y = lerp(group.current.position.y, -60, 0.05);
        break;
      default:
        break;
    }
  });

  const chrome = new MeshPhongMaterial({
    color: 0xeeeeee,
    envMap: envMap,
    reflectivity: 1,
    shininess: 100,
    side: DoubleSide,
  });

  return (
    <group ref={group} {...props} dispose={null} position={[0, -10, 0]}>
      <group name="Scene">
        <group name="Armature" rotation={[Math.PI / 2, 0, 0]} scale={6}>
          <primitive object={nodes.mixamorigHips} />
          <skinnedMesh
            name="ZBrush_defualt_group"
            geometry={nodes.ZBrush_defualt_group.geometry}
            material={chrome}
            skeleton={nodes.ZBrush_defualt_group.skeleton}
            castShadow
          />
        </group>
      </group>
    </group>
  );
};

useGLTF.preload("./assets/models/Kim.glb");

export default Kim;
