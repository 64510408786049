import { useLoader } from "@react-three/fiber";
import { TextureLoader, RepeatWrapping } from "three";

const Floor = () => {

    const map = useLoader(TextureLoader, "./assets/textures/concrete.jpg");
   map.wrapS =map.wrapT = RepeatWrapping;
   map.repeat.set(100, 100);

  return (
    <mesh
    position={[0, 0, 0]}
    rotation-x={-Math.PI / 2}
    castShadow
    receiveShadow
  >
    <planeGeometry args={[3000, 3000]} />
    <meshStandardMaterial
    color={0xBBBBBB}
    map={map}
    bumpMap={map}
    bumpScale={0.1}
    />
  </mesh>

  )
}

export default Floor;