import { useLoader } from "@react-three/fiber";
import {
  TextureLoader,
  EquirectangularReflectionMapping,
} from "three";

const Altar = () => {
  const envMap = useLoader(TextureLoader, "./assets/textures/hdricopy.png");
  envMap.mapping = EquirectangularReflectionMapping;
  return (
    <mesh
      position={[0, -1.5, 0]}
      rotation-x={-Math.PI / 2}
      castShadow
      receiveShadow
    >
      <torusGeometry args={[40, 3, 30, 200]} />
      <meshPhongMaterial
        color={0xeeeeee}
        specular={0xFFFFFF}
        envMap={envMap}
        reflectivity={1}
        shininess={100}
      />
    </mesh>
  );
};

export default Altar;
